<template>
  <div class="main">
    <div class="outer-padding">
      <div class="complex">
        <div class="title">综合体</div>
        <div class="eat">
          <div class="item-title">上周助餐</div>
          <div>
            <span class="item-num">{{
              number2TenThousand(syntheses.serviceInfo.insHelpMealCount)
            }}</span>
            <span class="item-unit">{{
              number2TenThousandUnit(syntheses.serviceInfo.insHelpMealCount)
            }}</span>
          </div>
        </div>
        <div class="eat">
          <div class="item-title">参与娱乐活动</div>
          <div>
            <span class="item-num">{{
              number2TenThousand(syntheses.serviceInfo.insActivityCount)
            }}</span>
            <span class="item-unit">{{
              number2TenThousandUnit(syntheses.serviceInfo.insActivityCount)
            }}</span>
          </div>
        </div>
        <div class="eat">
          <div class="item-title">参与康养服务</div>
          <div>
            <span class="item-num">{{
              number2TenThousand(syntheses.serviceInfo.insServiceCount)
            }}</span>
            <span class="item-unit">
              {{
                number2TenThousandUnit(syntheses.serviceInfo.insServiceCount)
              }}</span
            >
          </div>
        </div>
      </div>
      <div class="care">
        <div class="title">日间照料</div>
        <div class="eat">
          <div class="item-title">上周助餐</div>
          <div>
            <span class="item-num">{{
              number2TenThousand(syntheses.serviceInfo.careHelpMealCount)
            }}</span>
            <span class="item-unit">{{
              number2TenThousandUnit(syntheses.serviceInfo.careHelpMealCount)
            }}</span>
          </div>
        </div>
        <div class="eat">
          <div class="item-title">参与娱乐活动</div>
          <div>
            <span class="item-num">{{
              number2TenThousand(syntheses.serviceInfo.careActivityCount)
            }}</span>
            <span class="item-unit">{{
              number2TenThousandUnit(syntheses.serviceInfo.careActivityCount)
            }}</span>
          </div>
        </div>
        <div class="eat">
          <div class="item-title">参加康复服务</div>
          <div>
            <span class="item-num">{{
              number2TenThousand(syntheses.serviceInfo.careServiceCount)
            }}</span>
            <span class="item-unit">{{
              number2TenThousandUnit(syntheses.serviceInfo.careServiceCount)
            }}</span>
          </div>
        </div>
      </div>
      <div class="service">
        <div class="service-item">
          <div class="item-title" style="margin-top: 0.3rem">累计服务人次</div>
          <div>
            <span class="item-num" style="color: #32cf57">{{
              number2TenThousand(syntheses.serviceInfo.totalServiceCount)
            }}</span>
            <span class="item-unit" style="color: #32cf57">{{
              number2TenThousandUnit(syntheses.serviceInfo.totalServiceCount)
            }}</span>
          </div>
          <div class="item-title" style="margin-top: 0.3rem">累计订单</div>
          <div>
            <span class="item-num" style="color: #32cf57">{{
              number2TenThousand(syntheses.serviceInfo.totalOrderCount)
            }}</span>
            <span class="item-unit" style="color: #32cf57">
              {{
                number2TenThousandUnitDan(syntheses.serviceInfo.totalOrderCount)
              }}
            </span>
          </div>
        </div>
        <div class="service-item">
          <div class="item-title" style="margin-top: 0.3rem">人均消费</div>
          <div>
            <span class="item-num" style="color: #22ffec">{{
              syntheses.serviceInfo.perCapitalConsumption
            }}</span>
            <span class="item-unit" style="color: #22ffec">元</span>
          </div>
          <div class="item-title" style="margin-top: 0.3rem">会员人数</div>
          <div>
            <span class="item-num" style="color: #22ffec">{{
              number2TenThousand(syntheses.serviceInfo.vipCount)
            }}</span>
            <span class="item-unit" style="color: #22ffec">{{
              number2TenThousandUnit(syntheses.serviceInfo.vipCount)
            }}</span>
          </div>
        </div>
      </div>
      <div class="hot-service">
        <div class="title">TOP5最热门服务</div>
        <div class="hot-service-list">
          <div
            class="list-item"
            v-for="(item, index) in topService"
            :key="index"
          >
            <div style="display: flex; align-items: center">
              <div class="index">{{ index + 1 }}</div>
              <div class="name">{{ item.key }}</div>
            </div>

            <div class="percent">
              <div
                class="inner-percent"
                :style="{
                  width:
                    item.value == 0
                      ? 0
                      : (item.value / topService[0].value) * 100 + '%',
                }"
              ></div>
            </div>
            <div class="value">{{ item.value }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSyntheses, getStatistics } from '@/api/homePage/left.js'
export default {
  data() {
    return {
      syntheses: {
        serviceInfo: {
          insHelpMealCount: 0, // 综合体上周助餐
          insActivityCount: 0, // 综合体参与娱乐活动
          insServiceCount: 0, // 综合体参与康养服务
          careHelpMealCount: 0, // 日间照料上周助餐
          careActivityCount: 0, // 日间照料参与娱乐活动
          careServiceCount: 0, // 日间照料参与康养服务
          totalServiceCount: 0, // 累计服务人次
          totalOrderCount: 0, // 累计订单数量
          perCapitalConsumption: 0, // 人均消费
          vipCount: 0, // 会员人数
        },
      },
      dcTopService: [
        { key: '助餐', value: 1222 },
        { key: '体征检测', value: 823 },
        { key: '健康教育', value: 368 },
        { key: '文娱活动', value: 313 },
        { key: '理发', value: 289 },
      ],
      topService: [], // top5
    }
  },
  mounted() {
    this.getSyntheses()
    this.getStatistics()
  },
  computed: {
    //获取用户信息
    userInfo() {
      return JSON.parse(sessionStorage.getItem('statistics-gov-userInfo'))
    },
  },
  methods: {
    getStatistics() {
      getStatistics(this.userInfo.areaCode).then((res) => {
        if (res.code == 0) {
          this.syntheses = res.data
        }
      })
    },
    getSyntheses() {
      getSyntheses(this.userInfo.areaCode).then((res) => {
        if (res.code == 0) {
          if (this.userInfo.areaCode == 511703) {
            this.topService = this.dcTopService
          } else if (res.data.services.length == 0) {
            this.topService = [
              { key: '暂无', value: 0 },
              { key: '暂无', value: 0 },
              { key: '暂无', value: 0 },
              { key: '暂无', value: 0 },
              { key: '暂无', value: 0 },
            ]
          } else {
            this.topService = res.data.services
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    number2TenThousand(value) {
      if (value <= 10000) {
        return value
      } else {
        return (value / 10000).toFixed(2)
      }
    },
    number2TenThousandUnit(value) {
      if (value <= 10000) {
        return '人'
      } else {
        return '万人'
      }
    },
    number2TenThousandUnitDan(value) {
      if (value <= 10000) {
        return '单'
      } else {
        return '万单'
      }
    },
  },
}
</script>

<style scoped>
.main {
  height: 13rem;
  width: 24rem;
  background: url('~@/assets/images/homePage_images/comprehensiveBg.png');
  background-size: 100% 100%;
  overflow: hidden;
  margin-top: 0.3rem;
}
.outer-padding {
  margin: 1.83rem 0.32rem 0.5rem;
  height: calc(13rem - 2.33rem);
  width: calc(24.42rem - 0.84rem);
  display: grid;
  grid-template-columns: 4.83rem 4.83rem 4.83rem 8.08rem;
  grid-template-rows: repeat(2, 10.66rem);
  gap: 0.25rem;
}
.complex,
.hot-service,
.care,
.service {
  width: 4.83rem;
  height: 10.66rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 0.08rem;
  border: 0.04rem solid #213060;
}
.service {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: none;
}
.service-item {
  width: 100%;
  height: 5.16rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 0.08rem;
  border: 0.04rem solid #213060;
}
.hot-service {
  width: 8.08rem;
}
.eat {
  margin-bottom: 0.87rem;
}
.title {
  width: 100%;
  font-size: 0.58rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #69b1ff;
  line-height: 0.83rem;
  margin-left: 0.5rem;
  margin-top: 0.25rem;
  margin-bottom: 0.375rem;
}
.item-title {
  font-size: 0.5rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  line-height: 0.66rem;
  margin-left: 0.5rem;
}
.item-num {
  margin-left: 0.5rem;
  font-size: 1.25rem;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #ff8529;
  line-height: 1.45rem;
  margin-top: 0.2rem;
}
.item-unit {
  font-size: 0.5rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff8529;
  line-height: 0.66rem;
  margin-left: 0.1rem;
}
.list-item {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 1rem;
  margin-top: 0.7rem;
}
.list-item .index {
  width: 0.83rem;
  height: 0.83rem;
  font-size: 0.58rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #69b1ff;
  line-height: 0.83rem;
  background: #1b2955;
  border-radius: 0.08rem;
  text-align: center;
}
.list-item .name {
  font-size: 0.5rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  line-height: 0.66rem;
  margin-left: 0.16rem;
  max-width: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.list-item .percent {
  width: 2.5rem;
  height: 0.41rem;
  background: #1b2955;
  border-radius: 0.2rem;
}
.list-item .percent .inner-percent {
  height: 100%;
  background: linear-gradient(270deg, #ffcf43 0%, #ff9533 100%);
  border-radius: 0.2rem;
}
.list-item .value {
  font-size: 0.5rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  line-height: 0.66rem;
  max-width: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
